"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable react-hooks/rules-of-hooks */
const react_1 = require("react");
const react_router_dom_1 = require("react-router-dom");
const CustomMemoryRouter_1 = require("uni/components/routing/CustomMemoryRouter");
const useRouteMatchPath = (path) => {
    const isUsingMemoryRouter = (0, CustomMemoryRouter_1.useIsUsingMemoryRouter)();
    /**
     * When using MemoryRouter, the `history` object is not updated because the MemoryRouter
     * uses an internal state to manage the URL. This means that the `useHistory` hook
     * will not trigger a re-render when the URL changes. Instead, we have to use
     * `useRouteMatch` to get the params from the URL, as this reads the internal state
     * of the MemoryRouter.
     *
     * Also, we are okay breaking the rule of hooks here because the `isUsingMemoryRouter`
     * value will never change inside the component lifecycle. Either the component is always
     * in the MemoryRouter or it is not. So, we don't need to worry about the hook order
     * changing between renders.
     */
    if (isUsingMemoryRouter)
        return (0, react_router_dom_1.useRouteMatch)(path);
    const [routeMatchPathState, setRouteMatchPathState] = (0, react_1.useState)(() => {
        const match = (0, react_router_dom_1.matchPath)(window.location.pathname, path);
        return Boolean(match === null || match === void 0 ? void 0 : match.path);
    });
    const history = (0, react_router_dom_1.useHistory)();
    (0, react_1.useEffect)(() => {
        const unregisterHistoryListener = history.listen((location) => {
            const pathname = location.pathname;
            const match = (0, react_router_dom_1.matchPath)(pathname, path);
            const hasMatch = Boolean(match === null || match === void 0 ? void 0 : match.path);
            // only set the state if different
            if (hasMatch !== routeMatchPathState) {
                setRouteMatchPathState(hasMatch);
            }
        });
        return () => {
            unregisterHistoryListener();
        };
    }, [history, path, routeMatchPathState]);
    // on mount, check if URL changed between hook initialization and adding the event listener
    // (this runs only one time and occurs after the history.listener effect above)
    (0, react_1.useEffect)(() => {
        const match = (0, react_router_dom_1.matchPath)(window.location.pathname, path);
        const hasMatch = Boolean(match === null || match === void 0 ? void 0 : match.path);
        if (hasMatch !== routeMatchPathState) {
            setRouteMatchPathState(hasMatch);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return routeMatchPathState;
};
exports.default = useRouteMatchPath;
