"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MobileRow = exports.StopButton = exports.PlayButton = exports.PauseButton = exports.Controls = exports.TaskTitle = exports.TaskDetails = exports.Inner = exports.TimerDuration = exports.Outer = exports.radius = void 0;
const styled_components_1 = __importStar(require("styled-components"));
const colors_1 = require("uni/style/colors");
const devices_1 = require("uni/style/devices");
const text_1 = require("uni/style/text");
const styled_1 = require("../../styled");
const timerButtons_1 = require("../../timerButtons");
exports.radius = 11;
exports.Outer = styled_components_1.default.div `
  position: absolute;
  top: 0;
  left: 0;
  right: 0;

  z-index: 100;
`;
exports.TimerDuration = styled_components_1.default.div `
  ${text_1.bodyMedium}
  color: ${colors_1.gray5};
  position: absolute;
  right: 80px;
  padding: 3.5px 8px 3.5px 28px;
  opacity: 0; /* Initially hidden */
  transition: opacity 0s ease-in;

  ${(0, devices_1.screenM)((0, styled_components_1.css) `
    position: relative;
    right: 0;
    padding: 0;
    opacity: 1;
  `)}
`;
exports.Inner = styled_components_1.default.div `
  position: relative; /* For positioning TimerDuration */
  display: flex;
  padding: 10px 16px;
  align-items: center;
  justify-content: space-between;

  margin: 0 16px;

  border-radius: ${exports.radius}px;
  ${styled_1.cardStyle}

  &:hover ${exports.TimerDuration} {
    background: linear-gradient(
      270deg,
      ${colors_1.white} 77.5%,
      rgba(247, 248, 249, 0) 100%
    );
    opacity: 1;
  }

  ${(0, devices_1.screenM)((0, styled_components_1.css) `
    flex-direction: column;
  `)}
`;
exports.TaskDetails = styled_components_1.default.div `
  display: flex;
  gap: 12px;

  ${(0, devices_1.screenM)((0, styled_components_1.css) `
    width: 100%;
    justify-content: center;
  `)}
`;
exports.TaskTitle = styled_components_1.default.div `
  ${text_1.bodyMedium}
  max-width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
exports.Controls = styled_components_1.default.div `
  display: flex;
  gap: 8px;
  align-items: center;
`;
const buttonStyle = (0, styled_components_1.css) `
  padding: 2px;
`;
exports.PauseButton = (0, styled_components_1.default)(timerButtons_1.PauseButton) `
  ${buttonStyle}
`;
exports.PlayButton = (0, styled_components_1.default)(timerButtons_1.PlayButton) `
  ${buttonStyle}
`;
exports.StopButton = (0, styled_components_1.default)(timerButtons_1.StopButton) `
  ${buttonStyle}
`;
exports.MobileRow = styled_components_1.default.div `
  ${(0, devices_1.screenM)((0, styled_components_1.css) `
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    flex-wrap: wrap;
  `)}
`;
