"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const metrics_1 = require("uni/lib/metrics");
const types_1 = require("uni/lib/metrics/types");
const KEY = 'dbl-performance-printing';
const trackPrinting = () => {
    const w = window;
    if (!w[KEY]) {
        console.error(`trackPrinting: window['${KEY}'] can't be found. It must be added into html head.`);
        return;
    }
    if (w[KEY].error) {
        console.error(`trackPrinting: ${w[KEY].error.message}`);
        return;
    }
    if (!w[KEY].entries) {
        setTimeout(trackPrinting, 2000);
        return;
    }
    const entries = w[KEY].entries || {};
    Object.entries(entries).forEach(([name, duration]) => {
        metrics_1.metrics.track(types_1.TrackingEvent.APP_PERFORMANCE, {
            name,
            duration,
        });
    });
};
exports.default = trackPrinting;
