"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SuperBigButtonSecondary = exports.SuperBigButtonPrimary = exports.BigButtonSecondary = exports.BigButtonPrimary = exports.primaryButtonColors = exports.superBigButtonStyle = exports.purpleHoverGradient = exports.purpleBackgroundGradient = exports.mediumButtonSecondary = exports.mediumButtonPrimary = exports.mediumButtonBase = exports.ButtonSecondary = exports.buttonSecondary = exports.buttonPrimary = exports.buttonPrimaryNoDisabled = exports.buttonBase = void 0;
const styled_components_1 = __importStar(require("styled-components"));
const colors_1 = require("./colors");
const reset_1 = require("./reset");
const text_1 = require("./text");
exports.buttonBase = (0, styled_components_1.css) `
  ${text_1.calloutMedium}
  display: flex;
  height: 28px;
  border-radius: 8px;
  padding: 4px 10px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;
exports.buttonPrimaryNoDisabled = (0, styled_components_1.css) `
  ${exports.buttonBase}
  color: ${colors_1.white};
  background-color: ${colors_1.purple5};
  border: none;
`;
exports.buttonPrimary = (0, styled_components_1.css) `
  ${exports.buttonPrimaryNoDisabled}

  &:disabled {
    cursor: not-allowed;
    background: ${colors_1.purple4};
  }
`;
exports.buttonSecondary = (0, styled_components_1.css) `
  ${exports.buttonBase}
  color: ${colors_1.black};
  background-color: ${colors_1.white};
  border: 1px solid ${colors_1.gray3};

  &:disabled {
    cursor: not-allowed;
    background: mix(${colors_1.gray3}, white, 50%);
  }
`;
exports.ButtonSecondary = styled_components_1.default.button `
  ${exports.buttonSecondary}
`;
exports.mediumButtonBase = (0, styled_components_1.css) `
  ${text_1.bodyMedium}
  border: none;
  border-radius: 10px;
  padding: 7.5px 12px;
  cursor: pointer;
  height: 36px;
  align-items: center;
  justify-content: center;
  min-width: 75px;

  &:disabled {
    cursor: not-allowed;
    background: ${colors_1.gray1};
    color: ${colors_1.gray6};
  }
`;
exports.mediumButtonPrimary = (0, styled_components_1.css) `
  ${exports.mediumButtonBase}
  color: ${colors_1.white};
  background: ${colors_1.purple5};
`;
exports.mediumButtonSecondary = (0, styled_components_1.css) `
  ${exports.mediumButtonBase}
  color: ${colors_1.black};
  background: ${colors_1.white};
  border: 1px solid ${colors_1.gray3};
`;
exports.purpleBackgroundGradient = (0, styled_components_1.css) `
  background: linear-gradient(
      0deg,
      ${(0, colors_1.hexaToRgba)(colors_1.purple4, 0)} 0%,
      ${(0, colors_1.hexaToRgba)(colors_1.purple4, 0.7)} 100%
    ),
    ${colors_1.purple5};
`;
exports.purpleHoverGradient = (0, styled_components_1.css) `
  background: linear-gradient(
      0deg,
      ${(0, colors_1.hexaToRgba)(colors_1.purple4, 0.4)} 0%,
      ${(0, colors_1.hexaToRgba)(colors_1.purple4, 1)} 100%
    ),
    ${colors_1.purple5} !important;
`;
const bigButtonBase = (0, styled_components_1.css) `
  ${reset_1.buttonReset}
  display: flex;
  gap: 10px;
  border: none;
  cursor: pointer;

  align-items: center;
  justify-content: center;
  padding: 0 24px;
  &:disabled {
    cursor: not-allowed;
    background: ${colors_1.gray1};
    color: ${colors_1.gray6};
  }
`;
const bigButtonStyle = (0, styled_components_1.css) `
  ${text_1.bodyMedium}
  height: 44px;
  border-radius: 10px;
`;
exports.superBigButtonStyle = (0, styled_components_1.css) `
  ${text_1.titleMedium}
  height: 56px;
  border-radius: 12px;
`;
exports.primaryButtonColors = (0, styled_components_1.css) `
  ${exports.purpleBackgroundGradient}
  color: ${colors_1.white};
  &:hover:not(:disabled) {
    ${exports.purpleHoverGradient}
  }
`;
const secondaryButtonColors = (0, styled_components_1.css) `
  background: ${colors_1.white};
  color: ${colors_1.black};
  border: 1px solid ${colors_1.gray3};
  &:hover:not(:disabled) {
    background: ${colors_1.gray1};
  }
`;
exports.BigButtonPrimary = styled_components_1.default.button `
  ${bigButtonBase}
  ${bigButtonStyle}
  ${exports.primaryButtonColors}
`;
exports.BigButtonSecondary = styled_components_1.default.button `
  ${bigButtonBase}
  ${bigButtonStyle}
  ${secondaryButtonColors}
`;
exports.SuperBigButtonPrimary = styled_components_1.default.button `
  ${bigButtonBase}
  ${exports.superBigButtonStyle}
  ${exports.primaryButtonColors}
`;
exports.SuperBigButtonSecondary = styled_components_1.default.button `
  ${bigButtonBase}
  ${exports.superBigButtonStyle}
  ${secondaryButtonColors}
`;
