"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ACTIVE_SEARCH_ROUTE_PATHS = exports.ACTIVE_TOPIC_ID_ROUTE_PATHS = void 0;
const PATHS_1 = require("uni/lib/PATHS");
exports.ACTIVE_TOPIC_ID_ROUTE_PATHS = [
    '*/onboarding-checklist/:topicId',
    // Legacy "todos" paths
    `*/projects/:projectId${PATHS_1.TODOS}/:topicId`,
    '*/projects/:projectId',
    `*${PATHS_1.TODOS}/done/:topicId`,
    `*${PATHS_1.TODOS}/:topicId`,
    // New "tasks" paths
    '*/tasks/done/:topicId',
    '*/tasks/:topicId',
];
exports.ACTIVE_SEARCH_ROUTE_PATHS = ['*/search/:query'];
